import { faCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetPackageAnswerHistoryResponse_ActivityWithAnswer } from '@sparx/api/apis/sparx/packageactivity/v1/activity';
import {
  Package,
  TaskItem,
  TaskItem_Status,
} from '@sparx/api/apis/sparx/packageactivity/v1/package';
import * as annotations from '@sparx/packageactivity/src/annotations';
import { ISteps, rehydrateStepAnswer, SparxQuestion } from '@sparx/question';
import { useQuery } from '@tanstack/react-query';
import { getSchoolID } from 'api/auth';
import { activitiesClient } from 'api/clients';
import { useAssignmentPackages } from 'api/packages';
import { useSittingParticipantsMap, useWatchSitting } from 'api/sittings';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { useSchool } from 'components/ensuresession/EnsureSession';
import { LargeLoadingPage } from 'components/largeloading/LargeLoading';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { QuestionNavigator } from 'components/questionnavigator/QuestionNavigator';
import deepcopy from 'deepcopy';
import { AnimatePresence, motion } from 'framer-motion';
import React, { memo, ReactNode, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { SPARX_QUESTION_COLOURS } from 'utils/activities';
import { useGetTaskItems } from 'utils/packages';

import styles from './AnswerHistoryView.module.css';
import { getAssetUrl } from 'utils/assets';

export const AnswerHistoryView = () => {
  const params = useParams();
  const { data: sitting, isLoading } = useWatchSitting(params.sittingId || '', '');

  const { schoolId } = useSchool();
  const sittingName = `schools/${schoolId}/sittings/${params.sittingId || ''}`;
  const { data: participants } = useSittingParticipantsMap(sittingName, {
    enabled: Boolean(sitting && schoolId),
  });
  const { data: packages } = useAssignmentPackages(sittingName, {
    suspense: true,
    enabled: Boolean(sitting && schoolId),
  });

  const { participant, student } = participants?.get(params.userId || '') || {};
  const studentId = participant?.participantSubject?.split('/')[1];
  const pkgs = packages?.studentPackages?.filter(p => p.studentId === studentId);
  const pkgName = pkgs?.[0]?.package?.name;

  if (isLoading) {
    return <LargeLoadingPage />;
  }
  if (!sitting?.sitting || !pkgName || !student || !participant) {
    return <>Not found</>;
  }

  return (
    <PageContainer filled={true}>
      <PageHeader
        back={`/teacher/sittings/${sitting.sitting.sittingName.split('/')[3]}`}
        title={`${student?.givenName} ${student?.familyName} - ${sitting.sitting.title}`}
      >
        {sitting.sitting.title}
        <PageHeaderSubpage>
          {student?.givenName} {student?.familyName}
        </PageHeaderSubpage>
      </PageHeader>
      <PackageAnswerHistory packageName={pkgName} />
    </PageContainer>
  );
};

interface PackageAnswerHistoryProps {
  packageName: string;
  before?: (pkg: Package) => ReactNode | undefined;
  control?: (pkg: Package, taskItem: TaskItem) => ReactNode | undefined;
}

export const PackageAnswerHistory = ({
  packageName,
  before,
  control,
}: PackageAnswerHistoryProps) => {
  const { data } = useQuery({
    queryKey: ['package', packageName, 'answers'],
    queryFn: async () =>
      activitiesClient.getPackageAnswerHistory({
        packageName: packageName,
        schoolName: `schools/${await getSchoolID()}`,
      }).response,
    suspense: true,
  });

  const [params, setParams] = useSearchParams();
  const selectedTaskItemName = parseInt(params.get('q') || '0');

  const sortedActivities = useMemo(
    () =>
      data?.activities?.sort(
        (a, b) =>
          (a.activity?.endTimestamp?.seconds || 0) - (b.activity?.endTimestamp?.seconds || 0) ||
          (a.activity?.startTimestamp?.seconds || 0) - (b.activity?.startTimestamp?.seconds || 0),
      ) || [],
    [data?.activities],
  );

  const { selectedTaskItem, nextTaskItem, prevTaskItem, flatItems } = useGetTaskItems(
    data?.package,
    selectedTaskItemName,
  );
  const setSelectedQuestionName = (questionName: string) =>
    setParams(p => {
      p.set('q', flatItems?.findIndex(i => i?.name === questionName).toString() || '');
      return p;
    });

  const filteredActivities = sortedActivities
    .filter(a => a.activity?.taskItemName === selectedTaskItem?.name)
    .sort(
      (a, b) =>
        (b.activity?.startTimestamp?.seconds || 0) - (a.activity?.startTimestamp?.seconds || 0) ||
        (b.activity?.endTimestamp?.seconds || 0) - (a.activity?.endTimestamp?.seconds || 0),
    );

  if (!data?.package) {
    throw new Error('Package not found');
  }

  const controlElement = selectedTaskItem ? control?.(data.package, selectedTaskItem) : undefined;

  const container = (
    <div className={styles.Container}>
      <div className={styles.Questions}>
        <QuestionNavigator
          pkg={data.package}
          currentTaskItem={selectedTaskItem?.name}
          setCurrentTaskItem={setSelectedQuestionName}
          showResult={true}
          showAITasks={true}
        />
      </div>
      <div className={styles.Activities}>
        {selectedTaskItem && (
          <div className={styles.ActivityTop}>
            <div className={styles.ActivityHeader}>
              <strong className={styles.QuestionIndex}>
                Question {selectedTaskItem.title || selectedTaskItem.name.split('/')[5]}
              </strong>
            </div>
            <div className={styles.ActivityMeta}>
              <StatusIcon status={selectedTaskItem.state?.status} />
              <span className={styles.Time}>
                <FontAwesomeIcon icon={faClock} />
                {getTimeTakenString(filteredActivities)}
              </span>
            </div>
          </div>
        )}
        <div className={styles.ActivityContent}>
          {filteredActivities.length === 0 && (
            <div className={styles.NoAttempts}>No attempts at this question</div>
          )}
          <AnimatePresence initial={false}>
            {filteredActivities.map(a => (
              <motion.div
                className={styles.StepsContainer}
                key={a.activity?.name}
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
              >
                <div className={styles.Steps} key={a.activity?.name}>
                  <StepView activityWithAnswer={a} />
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
        {controlElement && <div className={styles.Control}>{controlElement}</div>}
        <div className={styles.ActivityQuestionNavigator}>
          <Button
            isDisabled={!prevTaskItem}
            onClick={() => prevTaskItem && setSelectedQuestionName(prevTaskItem.name)}
            leftIcon={<FontAwesomeIcon icon={faChevronUp} />}
          >
            Previous Question
          </Button>
          <Button
            isDisabled={!nextTaskItem}
            onClick={() => nextTaskItem && setSelectedQuestionName(nextTaskItem.name)}
            leftIcon={<FontAwesomeIcon icon={faChevronDown} />}
          >
            Next Question
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {before?.(data.package)}
      {container}
    </>
  );
};

const StatusIcon = memo(({ status }: { status?: TaskItem_Status }) => {
  let statusClassName = styles.StatusUnattempted;
  let statusText = 'Unattempted';
  let statusIcon = faCircle;
  switch (status) {
    case TaskItem_Status.CORRECT:
      statusText = 'Correct';
      statusClassName = styles.StatusCorrect;
      statusIcon = faCheck;
      break;
    case TaskItem_Status.INCORRECT:
      statusText = 'Incorrect';
      statusClassName = styles.StatusIncorrect;
      statusIcon = faTimes;
      break;
  }

  return (
    <div className={classNames(styles.Status, statusClassName)}>
      <FontAwesomeIcon icon={statusIcon} />
      {statusText}
    </div>
  );
});

const QUESTION_RENDERER_FONT_SIZE = '15';

const StepView = ({
  activityWithAnswer,
}: {
  activityWithAnswer: GetPackageAnswerHistoryResponse_ActivityWithAnswer;
}) => {
  const activity = activityWithAnswer.activity!;
  const skillActivity = activity.state?.skillActivity;
  const isMultiStep = annotations.isMultiStep(activity.annotations);
  const steps = useMemo(() => {
    const questionJSON = skillActivity?.question?.questionJson;
    try {
      const steps = JSON.parse(questionJSON || '') as ISteps;
      return steps
        .map((step, i) => {
          const stepEvaluations = skillActivity?.stepEvaluations[i]?.evaluations;
          const attempts = isMultiStep ? stepEvaluations || [] : [skillActivity?.evaluation];

          const correctAnswer = {
            status: TaskItem_Status.CORRECT,
            submittedAnswer: activityWithAnswer.correctAnswer,
            gapEvaluations: activityWithAnswer.correctAnswerGapEvaluations,
          };

          return { ...step, attempts, correctAnswer, index: i };
        })
        .reverse();
    } catch (e) {
      console.error('Error parsing question JSON', questionJSON, e);
      return undefined;
    }
  }, [activityWithAnswer, isMultiStep, skillActivity]);

  const showCorrect = false;
  return (
    <>
      {steps?.map((step, i) => {
        const input = deepcopy(step.input);

        const answer = showCorrect ? step.correctAnswer : step.attempts[0];
        const gapEvaluation = answer?.gapEvaluations;
        if (answer?.submittedAnswer) {
          rehydrateStepAnswer(input, answer?.submittedAnswer);
        }

        const stepLayout = (
          <SparxQuestion
            key={i}
            layout={step.layout}
            input={input}
            gapEvaluations={gapEvaluation}
            setInput={() => {}}
            readOnly={true}
            colours={SPARX_QUESTION_COLOURS}
            fontSize={QUESTION_RENDERER_FONT_SIZE}
            /* The same seed is used in the student view also */
            shuffleSeed={activity.name}
            mode="combined"
            sendAnalyticEvent={() => {}}
            imageContainerClassName={styles.ActivityImageContainer}
            getAssetUrl={getAssetUrl}
          />
        );

        return (
          <React.Fragment key={step.index}>
            {stepLayout}

            <strong className={styles.ActivityMarks}>
              [{skillActivity?.evaluation?.marks || 0} / {step.marks || 1} mark
              {(step.marks || 1) === 1 ? '' : 's'}]
            </strong>
          </React.Fragment>
        );
      })}
    </>
  );
};

const getTimeTakenString = (
  activityWithAnswer: GetPackageAnswerHistoryResponse_ActivityWithAnswer[],
) => {
  let timeTaken = 0;
  for (const activity of activityWithAnswer) {
    timeTaken += Math.round(activity.questionTimeSeconds + activity.supportTimeSeconds);
  }
  const minutes = Math.floor(timeTaken / 60);
  const timeTakenString =
    minutes > 0
      ? `${minutes} minute${minutes > 1 ? 's' : ''}`
      : `${timeTaken} second${timeTaken > 1 ? 's' : ''}`;

  return timeTakenString;
};
